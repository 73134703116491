import React from 'react';
import { Page, EmptyState } from '@shopify/polaris';

export default function PlanChangedPage() {
  return (
    <Page>
      <br />
      <br />
      <EmptyState
        heading="Plan changed sucessfully"
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      >
        <p>Please close this window to go back to the app</p>
      </EmptyState>
    </Page>
  );
}
