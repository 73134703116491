import React, { useState, useContext } from 'react';
import {
  Page,
  Card,
  Banner,
  Checkbox,
  Form,
  FormLayout,
  Button,
  Toast,
  Frame,
  Badge,
  FooterHelp,
} from '@shopify/polaris';
import {
  MerchantContext,
  MerchantDataProvider,
  saveMerchant,
} from '../components/MerchantDataProvider';
const SlackInstallButton = ({ installURL }) => {
  return (
    <a href={installURL} target="_blank" rel="noreferrer">
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  );
};

const PlanBanner = ({ merchantData }) => {
  const text =
    merchantData.plan !== 'PRO' ? (
      <p style={{ marginBottom: 15 }}>
        You are currently on the <b>FREE</b> plan. This means you are limited to
        100 Slack notifications per day. Please upgrade your plan to unlock
        unlimited events and more notifications types.
      </p>
    ) : (
      <p style={{ marginBottom: 15 }}>
        You are currently on the <b>PRO</b> plan. This means you have unlimited
        Slack notifications per day and access to all features.
      </p>
    );
  const billingUrl = `/app/billing?mid=${merchantData.id}`;
  return (
    <Card title="Plan">
      <Card.Section>
        {text}
        <p>
          <Button url={billingUrl}>View other plans</Button>
        </p>
      </Card.Section>
    </Card>
  );
};

const PaidBadge = (str, isPaid) => {
  if (isPaid) return str;
  return (
    <>
      {str} &nbsp;&nbsp;
      <Badge>Pro plan only</Badge>
    </>
  );
};

const SlackInstallBanner = ({ merchantData }) => {
  if (merchantData.slackInstalled) {
    return null;
  }
  return (
    <div style={{ marginBottom: 15 }}>
      <Banner title="You need to connect Slack" status="warning">
        <p>
          To complete installation please click the button to connect to your
          workspace.
        </p>
        <p style={{ margin: '10px 0 0 0' }}>
          <SlackInstallButton installURL={merchantData.slackInstallButtonURL} />
        </p>
      </Banner>
    </div>
  );
};

export function AppPageInner() {
  const merchantDataIn = useContext(MerchantContext);
  const [merchantData, setMerchantData] = useState(merchantDataIn);
  const [isSaving, setIsSaving] = useState(false);
  const [didSave, setDidSave] = useState(false);
  if (!merchantData) {
    // Error page?
  }

  const isPaid = merchantData.plan === 'PRO';

  const sevents = merchantData.shopifyEvents || {};
  if (!isPaid) {
    merchantData.shopifyEvents['orders/updated'] = false;
    merchantData.shopifyEvents['orders/fulfilled'] = false;
    merchantData.shopifyEvents['orders/partially_fulfilled'] = false;
    merchantData.shopifyEvents['refunds/create'] = false;
    merchantData.shopifyEvents['customers/create'] = false;
    merchantData.shopifyEvents['customers/update'] = false;
  }
  const setSetting = (key, value) => {
    const newState = { ...merchantData };
    newState[key] = value;
    newState.changed = true;
    setMerchantData(newState);
  };
  const setEvent = (key, value) => {
    const newState = { ...merchantData };
    newState.shopifyEvents[key] = value;
    newState.changed = true;
    setMerchantData(newState);
  };
  const isChanged = !!merchantData.changed;

  const handleSaveSettings = async () => {
    setIsSaving(true);
    await saveMerchant({
      shopifyEvents: merchantData.shopifyEvents,
      dailyUpdateEnabled: merchantData.dailyUpdateEnabled,
    });
    // console.log(merchantData.shopifyEvents);
    // setTimeout(() => {
    const newState = { ...merchantData };
    newState.changed = false;
    setMerchantData(newState);
    setIsSaving(false);
    setDidSave(true);
    // }, 1000);
  };

  const hideToast = () => {
    setDidSave(false);
  };

  const toastMarkup = didSave ? (
    <Toast content="Settings saved" onDismiss={hideToast} />
  ) : null;

  return (
    <>
      <SlackInstallBanner merchantData={merchantData} />
      <PlanBanner merchantData={merchantData} />
      <Card title="Settings">
        <Card.Section title="Slack automated reports">
          <p>
            Select which Slack automated reports you would like sent to channel
            &nbsp;<b>{merchantData.slackChannel}</b>
          </p>
          <br />
          <Form>
            <FormLayout>
              <Checkbox
                label="Daily sales summary update sent at 6am local time"
                checked={merchantData.dailyUpdateEnabled}
                onChange={(v) => setSetting('dailyUpdateEnabled', v)}
              />
            </FormLayout>
          </Form>
        </Card.Section>

        <Card.Section title="Shopify event notifications">
          <p>
            Please select which event notifications are sent to Slack in the
            channel <b>{merchantData.slackChannel}</b>
          </p>
          <br />
          <Form>
            <FormLayout>
              <Checkbox
                name="orders/create"
                label="Order Create"
                checked={sevents['orders/create']}
                onChange={(v) => setEvent('orders/create', v)}
              />
              <Checkbox
                name="orders/cancelled"
                label="Order Cancelled"
                checked={sevents['orders/cancelled']}
                onChange={(v) => setEvent('orders/cancelled', v)}
              />
              <Checkbox
                label={PaidBadge('Order Update', isPaid)}
                disabled={!isPaid}
                checked={sevents['orders/updated']}
                onChange={(v) => setEvent('orders/updated', v)}
              />
              <Checkbox
                label={PaidBadge('Order Fulfilled', isPaid)}
                disabled={!isPaid}
                checked={sevents['orders/fulfilled']}
                onChange={(v) => setEvent('orders/fulfilled', v)}
              />
              <Checkbox
                label={PaidBadge('Order Partially Fulfilled', isPaid)}
                disabled={!isPaid}
                checked={sevents['orders/partially_fulfilled']}
                onChange={(v) => setEvent('orders/partially_fulfilled', v)}
              />
              <Checkbox
                label={PaidBadge('Refund created', isPaid)}
                disabled={!isPaid}
                checked={sevents['refunds/create']}
                onChange={(v) => setEvent('refunds/create', v)}
              />
              <Checkbox
                label={PaidBadge('Customer created', isPaid)}
                disabled={!isPaid}
                checked={sevents['customers/create']}
                onChange={(v) => setEvent('customers/create', v)}
              />
              <Checkbox
                label={PaidBadge('Customer updated', isPaid)}
                disabled={!isPaid}
                checked={sevents['customers/update']}
                onChange={(v) => setEvent('customers/update', v)}
              />
              <Button
                primary
                submit
                loading={isSaving}
                disabled={!isChanged}
                onClick={handleSaveSettings}
              >
                Save settings
              </Button>
            </FormLayout>
          </Form>
        </Card.Section>
      </Card>

      <Card title="Connected accounts">
        <Card.Section title="Slack">
          <div style={{ float: 'right' }}>
            <SlackInstallButton
              installURL={merchantData.slackInstallButtonURL}
            />
          </div>
          <p>
            Connected to slack worspace <b>{merchantData.slackTeamName}</b> and
            channel <b>{merchantData.slackChannel}</b>. To change slack
            workspace or default channel please reconnect using add to slack
            button.
          </p>
        </Card.Section>
        <Card.Section title="Shopify">
          <p>
            Connected to store{' '}
            <b>
              <a
                href={'https://' + merchantData.shopifyShop + '/'}
                target="_blank"
                rel="noreferrer"
              >
                {merchantData.shopifyShop}
              </a>
            </b>
          </p>
        </Card.Section>
      </Card>
      <FooterHelp>
        Please contact us at help@sknap.com for support or to say hi!
      </FooterHelp>
      {toastMarkup}
    </>
  );
}

export default function AppPage() {
  return (
    <Frame>
      <Page title="Sales bot">
        <MerchantDataProvider>
          <AppPageInner />
        </MerchantDataProvider>
      </Page>
    </Frame>
  );
}
