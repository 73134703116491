import React from 'react';

export default function HomePage() {
  return (
    <div>
      <h1 className="header">Welcome To ShopBot</h1>
      <p>Install me from Shopify!</p>
    </div>
  );
}
