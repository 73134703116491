import React, { useContext } from 'react';
import {
  Page,
  Card,
  Frame,
  Layout,
  Button,
  FooterHelp,
  TextStyle,
} from '@shopify/polaris';
import {
  MerchantContext,
  MerchantDataProvider,
  getChangePlanURL,
} from '../components/MerchantDataProvider';

const Price = ({ period, price }) => {
  return (
    <>
      <b>{price}</b> {period}
    </>
  );
};

export function BillingPageInner() {
  const merchantData = useContext(MerchantContext);
  const isFree = merchantData.plan === 'FREE';
  const isPro = merchantData.plan === 'PRO';
  return (
    <>
      <Layout>
        <Layout.Section oneHalf>
          <Card title={isFree ? 'Free plan (current plan)' : 'Free plan'}>
            <Card.Section>
              <Price period="monthly" price="$0" />
              <ul className="features">
                <li className="featureIncluded">Order notifications</li>
                <li className="featureNotIncluded">Customer notifications</li>
                <li className="featureNotIncluded">Refund notifications</li>
                <li className="featureIncluded">
                  1000 notifications per month limit
                </li>
              </ul>
              <p>
                <Button
                  primary
                  disabled={isFree}
                  url={getChangePlanURL('FREE', merchantData.shopifyShop)}
                  external
                >
                  Select plan
                </Button>
              </p>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card title={isPro ? 'Pro plan (current plan)' : 'Pro plan'}>
            <Card.Section>
              <Price period="monthly" price="$9.99" /> (14 day free trial)
              <ul className="features">
                <li className="featureIncluded">Order notifications</li>
                <li className="featureIncluded">Customer notifications</li>
                <li className="featureIncluded">Refund notifications</li>
                <li className="featureIncluded">
                  Unlimited notifications per month
                </li>
              </ul>
              <p>
                <Button
                  primary
                  disabled={isPro}
                  url={getChangePlanURL('PRO', merchantData.shopifyShop)}
                  external
                >
                  Select plan
                </Button>
                &nbsp;{' '}
                <TextStyle variation="subdued">
                  Payment will be added to your Shopify bill.
                </TextStyle>
              </p>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <FooterHelp>
        Please contact us at help@sknap.com for support or to say hi!
      </FooterHelp>
    </>
  );
}

function BillingPagePage() {
  const merchantData = useContext(MerchantContext);
  return (
    <Frame>
      <Page
        title="Sales bot plans and billing"
        breadcrumbs={[{ content: 'App', url: '/app?mid=' + merchantData.id }]}
      >
        <BillingPageInner />
      </Page>
    </Frame>
  );
}

export default function BillingPage() {
  return (
    <MerchantDataProvider>
      <BillingPagePage />
    </MerchantDataProvider>
  );
}
