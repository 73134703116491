import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';

import HomePage from './pages/HomePage';
import AppPage from './pages/AppPage';
import InstalledPage from './pages/InstalledPage';
import BillingPage from './pages/BillingPage';
import PlanChangedPage from './pages/PlanChangedPage';

import '@shopify/polaris/dist/styles.css';
import './App.css';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const mid = urlParams.get('mid');
  if (mid) {
    try {
      localStorage.setItem('mid', mid);
    } catch (e) {
      window.mid = mid;
    }
  }

  return (
    <AppProvider i18n={enTranslations}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/app/billing" component={BillingPage} />
          <Route path="/app/installed" component={InstalledPage} />
          <Route path="/app/plan-changed" component={PlanChangedPage} />
          <Route path="/app" component={AppPage} />
        </Switch>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
