import React, { useState, useEffect } from 'react';
import { Spinner } from '@shopify/polaris';

export const MerchantContext = React.createContext(null);

const MERCHANT_API_BASE = 'https://api.shopbot.sknap.com/prod/app/merchant/';

const getMerchantCode = () => {
  try {
    return localStorage.getItem('mid');
  } catch (e) {
    return window.mid;
  }
};

export const getChangePlanURL = (plan, shop) => {
  return (
    'https://api.shopbot.sknap.com/prod/shopify/plan/' +
    getMerchantCode() +
    '/' +
    shop +
    '/' +
    plan +
    '?' +
    Math.round(Math.random() * 999999)
  );
};

export const saveMerchant = async (data) => {
  console.log(data);
  const url = MERCHANT_API_BASE + getMerchantCode();
  return await fetch(url, { method: 'POST', body: JSON.stringify(data) });
};

export function MerchantDataProvider({ children }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const url = MERCHANT_API_BASE + getMerchantCode();
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // data.plan = 'FREE';
        // data.slackInstalled = false;
        data.id = getMerchantCode();
        setData(data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner accessibilityLabel="Loading..." size="large" />;
  }
  return (
    <MerchantContext.Provider value={{ ...data }}>
      {children}
    </MerchantContext.Provider>
  );
}
